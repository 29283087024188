import React from "react"
import { navigate } from "gatsby"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit } from "@fortawesome/free-solid-svg-icons"

import Button from "../Elements/Button"
import styles from "./utils/profile.module.scss"

const EditDetailsButton = ({ route, backRoute, className }) => {
  const handleNavigate = () => {
    if (backRoute) {
      navigate(route, { state: { backRoute } })
    } else {
      navigate(route)
    }
  }

  return (
    <Button
      onClick={handleNavigate}
      size="small"
      variant="outlined"
      className={classNames(
        styles["section__buttonBorderless"],
        className || ""
      )}
    >
      <span className="icon">
        <FontAwesomeIcon icon={faEdit} />
      </span>
      <span>Edit</span>
    </Button>
  )
}

export default EditDetailsButton
