import React, { useContext } from "react"
import { Link } from "gatsby"
import classNames from "classnames"

import DeleteAddressModal from "./DeleteAddressModal"

import styles from "../utils/profile.module.scss"
import { AppContext } from "../../../context/AppContext"
import { makeDefaultAddress } from "../services/addresses"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faMapMarkedAlt,
  faHome,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons"

const AddressContainer = ({
  address,
  index,
  handleClick,
  nextRoute,
  backRoute,
  activeAddress,
  setAddresses,
  handleDeleteCallback,
  setActiveAddress,
}) => {
  const { dispatch } = useContext(AppContext)

  const handleDeleteAddress = ({ address, index }) => {
    dispatch({
      type: "SHOW_MODAL",
      payload: {
        heading: "Delete Address",
        isCard: true,
        content: (
          <DeleteAddressModal
            address={address}
            index={index}
            handleDeleteCallback={handleDeleteCallback}
          />
        ),
      },
    })
  }

  return (
    <div
      className={classNames("box is-shadowless", styles["box"], {
        "has-background-light": activeAddress === index,
        [styles["box__clickable"]]: !!handleClick,
      })}
      onClick={() => {
        if (handleClick) handleClick(index)
      }}
    >
      <p className="is-size-6">
        <span className="icon mr-1">
          <FontAwesomeIcon
            icon={address.type == "Home" ? faHome : faBuilding}
          />
        </span>{" "}
        Address Type:{" "}
        <span className="has-text-weight-bold is-size-6 is-capitalized">
          {address.type}{" "}
          {address.primary && (
            <span className="ml-1 tag is-rounded">Default</span>
          )}
        </span>
      </p>
      <p className="is-size-6">
        <span className="icon mr-1">
          <FontAwesomeIcon icon={faMapMarkedAlt} />
        </span>{" "}
        {address.street || address.streetAddress},{" "}
        {address.barangay ? `${address.barangay}, ` : ""}
        {address.city}, {address.province}{" "}
        {address.notes ? `(${address.notes})` : ""}
      </p>
      <p className="is-size mt-2">
        <div className="buttons are-small">
          <Link
            className="button is-info has-text-white"
            to="/profile/edit-address"
            state={{ address: { ...address, index }, index: index }}
          >
            Edit
          </Link>
          {!address.primary && (
            <button
              type="button"
              className="button"
              onClick={() => {
                makeDefaultAddress({
                  index,
                  callback: (addressDocument) => {
                    dispatch({
                      type: "SHOW_TOAST",
                      payload: {
                        message: `Successfully updated primary address`,
                        color: "success",
                      },
                    })
                    setAddresses(addressDocument)
                  },
                })
              }}
            >
              Set as primary
            </button>
          )}
        </div>
      </p>
    </div>
  )
}

export default AddressContainer
